import axiosClient from './axiosBase';

/**
 * Use this method to perform a HTTP GET request to a API server
 * @param {string} endPoint The API end point to request which will be concatinated with base URL
 */
const getAsync = async (endPoint) => {
    return axiosClient.get(endPoint);
}

/**
 * Use this method to perform a HTTP POST request to a API server
 * @param {string} endPoint The API end point to request which will be concatinated with base URL
 * @param {object} payload The payload which will be sent with the request in the request body
 */
const postAsync = async (endPoint, payload) => {
    return axiosClient.post(endPoint, payload);
}

/**
 * Use this method to perform a HTTP POST request to a API server
 * @param {string} endPoint The API end point to request which will be concatinated with base URL
 * @param {object} payload The payload which will be sent with the request in the request body
 */
const putAsync = async (endPoint, payload) => {
    return axiosClient.put(endPoint, payload);
}

/**
 * Use this method to perform a HTTP POST request to a API server
 * @param {string} endPoint The API end point to request which will be concatinated with base URL
 */
const deleteAsync = async (endPoint, payload) => {
    if (payload) {
        return axiosClient.delete(endPoint, { data: payload })
    }
    return axiosClient.delete(endPoint);
}

export { deleteAsync, getAsync, postAsync, putAsync }
