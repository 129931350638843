/* eslint-disable no-useless-computed-key */
import { ShipmentStepperEnum } from "../enums/common-enum";

export const AssignedUserModel = {
    id: null,
    mail: null,
    fullName: null,
    givenName: null,
    surname: null,
    userPrincipalName: null,
}

export const ShipmentDetailsModel = {
    id: '',
    bidTypeName: '',
    customerName: '',
    customerId: '',
    calculatedTransitTime: '',
    totalMiles: '',
    calculatedMiles: '',
    origin: '',
    originCountry: '',
    originZipCode: '',
    originStateCode: '',
    originCity: '',
    destination: '',
    destinationCountry: '',
    destinationZipCode: '',
    destinationStateCode: '',
    destinationCity: '',
    etaPickupDateTime: '',
    etaDeliveryDateTime: '',
    tarrif: '',
    fuelSurcharge: false,
    fuelSurchargeAmount: 0,
    rfqNote: '',
    shipmentNotes: '',
    vehicleTypeRequested: '',
    freightDetails: [],
    containers: '',
    shipmentHazmatDetails: [],
    isHazmat: false,
    isHazmatOnshipmentLevel: false,
    vehicleTypeSuggested: '',
    vehicleId: '',
    isTeam: false,
    teamTrip: '0',
    brokerable: '',
    shipmentNumber: '',
    shipmentRfqId: '',
    fields: null,
    routeLegId: '',
    isBordercrossing: false,
    assignTo: AssignedUserModel,
    declinedBy: '',
    submittedBy: '',
    isOriginAddrCore: false,
    isDestAddrCore: false,
    readyTime: '',
    latestPickupTime: '',
    pickupDateTime: '',
    deliveryDateTime: '',
    customerAccount: '',
    isIncludeFuelCostInQuote: false,
    rate: 0,
    rpm: 0,
    milesPerHour: 0,
    isMultiStop: false,
    isRoundTrip: false,
    isDockHigh: false,
    isFast: false,
    isLiftGate: false,
    stepperValue: ShipmentStepperEnum.new,
    awardResponseBy: null,
    awardResponseAt: null,
    awardedStatus: null,
    awardedStatusName: null,
    isShipmentUSToCanada: false,
    isExcessLead: false,
    isOverTariff: '',
    isFuelSurchargeApplied: '',
    bidFuelSurcharge: '',
    totalBidAmount: '',
    allInclusiveRate: '',
    vehicleTypeSelected: '',
    bidNotes: '',
    borderCrossingLocation: '',
    tariffReason: '',
    declineNote: '',
    declineReason: '',
    internalReason: '',
    expireDateTime: '',
    shipmentTypeName: '',
    shipmentCarrierId: '',
    deletedBy: '',
    deletedAt: null,
    bidSentOn: null,
    declineOn: null,
    bestMatchCount: 0,
    alternativeMatchCount: 0,
    noMatchCount: 0,
    repostCount: 0,
    source: 0,
    emailContent: '',
    emailFrom: '',
    emailReplyTo: '',
    linkAccept: '',
    linkDecline: '',
    pro: '',
    status: '',
    isExpired: false,
    isClicked: false,
    linkExpiryDateTime: '',
    weight: '',
    counterRate: '',
    emailBidType: null,
    allowCounterOffer: false
}

export const FreightDetailModel = {
    shipmentLineItem_Id: 0,
    shipmentId: '',
    numberOfContainers: '',
    quantity: '',
    length: '',
    lengthUnit: 'in',
    width: '',
    widthUnit: 'in',
    height: '',
    heightUnit: 'in',
    weight: '',
    weightUnit: 'lbs',
    isHazmat: false,
    stackable: false,
    looseLoad: false,
    dockHigh: false,
    liftGate: false,
    isLooseLoad: false
}

export const ProFormProps = {
    shipmentId: '',
    showProForm: false
}

export const AcceptFormProps = {
    shipmentId: '',
    showAcceptForm: false
}

export const RejectFormProps = {
    shipmentId: '',
    showRejectForm: false
}

export const RejectFormPayload = {
    rejectReasonType: '',
    quoteRejectedBy: '',
    notes: ''
}

export const RejectFormErrorFields = {
    rejectReasonType: '',
    quoteRejectedBy: ''
}

export const CounterFormProps = {
    shipmentId: '',
    showCounterForm: false
}

export const CounterFormPayload = {
    shipmentId: '',
    counterRate: '',
    counterOfferPlacedBy: ''
}

export const CounterFormErrorFields = {
    counterRate: '',
    counterOfferPlacedBy: ''
}

export const RejectReasonsSourceList = [
    {
        value: 1,
        text: 'Price'
    },
    {
        value: 2,
        text: 'Timing'
    },
    {
        value: 3,
        text: 'No longer Needed'
    }
]
