import { useEffect, useState } from "react";
import './form-loader.scss';

const FormLoader = (props) => {
    const [showFormLoader, setShowFormLoader] = useState(true)

    useEffect(() => {
        setShowFormLoader(props.showFormLoader);
    }, [props.showFormLoader]);
    
    return (
        <>
            {
                showFormLoader ? (
                    <div className="overlay-loader" >
                        <div className="form-loader"></div>
                    </div>
                ) : (<div></div>)
            }
        </>
    );
}

export default FormLoader;