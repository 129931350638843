import { CommonConstants } from '../constants/common-constants';
import { toastError } from '../services/toast-service';
import { redirectToLogin } from './common-utils';

// Can be used to intercept/process the responses of all the API requests
export const processResponse = (response) => {
    if (response && response.status !== 200) {
        if ([401, 403].indexOf(response.status) !== -1) {
            // Auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            if (window.location.pathname !== '/') {
                redirectToLogin();
            }
        }
    }
    return response;
}

export const processErrorResponse = (errorResponse) => {
    const { response } = errorResponse;
    if (response && response.status !== 200) {
        switch (response.status) {
            case 400:
                toastError(JSON.stringify(response.data.errors));
                break;
            case 401:
                toastError(CommonConstants.permissionErrorMessage);
                setTimeout(() => {
                    if (window.location.pathname !== '/') {
                        redirectToLogin();
                    }
                }, 1000);
                break;
            case 403:
                toastError(CommonConstants.sessionErrorMessage);
                setTimeout(() => {
                    if (window.location.pathname !== '/') {
                        redirectToLogin();
                    }
                }, 1000);
                break;
            case 404:
                toastError(CommonConstants.serverErrorMessage);
                break;
            default:
                break;
        }
    }
    return errorResponse;
}
