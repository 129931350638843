/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as _ from 'lodash';
import { useEffect, useState } from "react";
import { CommonConstants } from "../../../app-core/constants/common-constants";
import { FreightDetailModel, ShipmentDetailsModel } from "../../../app-core/data-model/common-data-model";
import { checkBooleanValues, checkDisplayValue } from "../../../app-core/helpers/common-method";
import "./freight-details.scss";

const FreightDetails = (props) => {

    const [freightView, setFreightView] = useState('gridView');
    const [isEditable, setIsEditable] = useState(false);
    const [freightDetailStatus, setFreightDetailStatus] = useState(CommonConstants.loading);
    const [freightDetailModel, setFreightDetailModel] = useState(Object.assign({}, FreightDetailModel));
    const [shipmentDetailsModel, setShipmentDetailsModel] = useState(Object.assign({}, ShipmentDetailsModel));
    const [totalWeight, setTotalWeight] = useState(0);

    useEffect(() => {
        if (!_.isEqual(shipmentDetailsModel, props.shipmentDetailsModel)) {
            if (!isEditable) {
                setFreightView(props.freightView);
                setShipmentDetailsModel(props.shipmentDetailsModel);
                setFreightDetailModel({
                    ...freightDetailModel,
                    shipmentId: props.shipmentDetailsModel.id
                });
                setTotalWeight(props.shipmentDetailsModel?.freightDetails?.map(item => item.weight).reduce((prev, next) => { if (Number.isInteger(next)) return prev + next; else return prev; }, 0));
            }
        }
        if (freightDetailStatus !== props.freightDetailStatus) {
            setFreightDetailStatus(props.freightDetailStatus);
        }
        if (isEditable !== props.isEditable) {
            setIsEditable(props.isEditable);
        }
    }, [props.shipmentDetailsModel, props.freightDetailStatus, props.isEditable]);

    return (
        <div className="freight-details-wrapper">
            <div className="heading">
                <span className="me-3">Freight Details (Total Weight - {totalWeight ? totalWeight : shipmentDetailsModel.weight} lbs)</span>
                {
                    shipmentDetailsModel.isDockHigh &&
                    <span className="heading-status-text dock-high-text me-3">Dock High</span>
                }
                {
                    shipmentDetailsModel.isLiftGate &&
                    <span className="heading-status-text">Lift Gate</span>
                }
            </div>
            {
                freightView === 'gridView' ? (
                    <>
                        <Paper className="paper-content" sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer className="table-wrapper">
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Containers</TableCell>
                                            <TableCell>Pieces</TableCell>
                                            <TableCell>Dimension (LxWxH) In</TableCell>
                                            <TableCell>Weight</TableCell>
                                            <TableCell>Stackable</TableCell>
                                            {
                                                shipmentDetailsModel.isHazmat && shipmentDetailsModel.isHazmatOnshipmentLevel &&
                                                <>
                                                    <TableCell>Hazmat</TableCell>
                                                    <TableCell>UN Number</TableCell>
                                                    <TableCell>Packaging Class</TableCell>
                                                    <TableCell>Code</TableCell>
                                                </>
                                            }
                                            {
                                                !shipmentDetailsModel.isHazmat &&
                                                <TableCell>Hazmat</TableCell>
                                            }
                                            <TableCell>Loose Load</TableCell>
                                            {
                                                isEditable &&
                                                <TableCell>Action</TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {
                                        shipmentDetailsModel.freightDetails.length === 0 ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={10}>
                                                        <div className="text-center">
                                                            {freightDetailStatus}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {
                                                    shipmentDetailsModel.freightDetails.map((item, index) => (
                                                        <TableRow
                                                            key={'freight_details_' + index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell>{item.numberOfContainers}</TableCell>
                                                            <TableCell>{item.quantity}</TableCell>
                                                            <TableCell>{item.length} x {item.width} x {item.height}</TableCell>
                                                            <TableCell>{item.weight} lbs</TableCell>
                                                            <TableCell>{checkBooleanValues(item.stackable)}</TableCell>
                                                            {
                                                                shipmentDetailsModel.isHazmat && shipmentDetailsModel.isHazmatOnshipmentLevel &&
                                                                <>
                                                                    <TableCell>{checkBooleanValues(item?.shipmentHazmatItems?.isHazmat)}</TableCell>
                                                                    <TableCell>{item?.shipmentHazmatItems?.unNumber}</TableCell>
                                                                    <TableCell>{item?.shipmentHazmatItems?.class}</TableCell>
                                                                    <TableCell>{item?.shipmentHazmatItems?.code}</TableCell>
                                                                </>
                                                            }
                                                            {
                                                                !shipmentDetailsModel.isHazmat &&
                                                                <>
                                                                    <TableCell>{checkBooleanValues(item.isHazmat)}</TableCell>
                                                                </>
                                                            }
                                                            <TableCell>{checkBooleanValues(item.isLooseLoad)}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        )
                                    }
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                ) : (
                    <>
                        {
                            Array.isArray(shipmentDetailsModel.freightDetails) && shipmentDetailsModel.freightDetails.length ? (
                                shipmentDetailsModel.freightDetails.map((item, index) => (
                                    <ul className="list" key={'freight_details_' + index}>
                                        <li>
                                            <label>Containers</label>
                                            <div className="text">{item.numberOfContainers}</div>
                                        </li>
                                        <li>
                                            <label>Pieces</label>
                                            <div className="text">{item.quantity}</div>
                                        </li>
                                        <li>
                                            <label>Dimension (LxWxH) In</label>
                                            <div className="text">{item.length} x {item.width} x {item.height}</div>
                                        </li>
                                        <li>
                                            <label>Weight</label>
                                            <div className="text">{item.weight} lbs</div>
                                        </li>
                                        <li>
                                            <label>Stackable</label>
                                            <div className="text">{checkBooleanValues(item.stackable)}</div>
                                        </li>
                                        {
                                            shipmentDetailsModel.isHazmat && shipmentDetailsModel.isHazmatOnshipmentLevel &&
                                            <>
                                                <li>
                                                    <label>Hazmat</label>
                                                    <div className="text">{checkBooleanValues(item.isHazmat)}</div>
                                                </li>
                                                <li>
                                                    <label>UN Number</label>
                                                    <div className="text">{checkDisplayValue(item.unNumber)}</div>
                                                </li>
                                                <li>
                                                    <label>Packaging Class</label>
                                                    <div className="text">{checkDisplayValue(item.class)}</div>
                                                </li>
                                                <li>
                                                    <label>Code</label>
                                                    <div className="text">{checkDisplayValue(item.code)}</div>
                                                </li>
                                            </>
                                        }
                                        {
                                            !shipmentDetailsModel.isHazmat &&
                                            <>
                                                <li>
                                                    <label>Hazmat</label>
                                                    <div className="text">{checkBooleanValues(item.isHazmat)}</div>
                                                </li>
                                            </>
                                        }
                                        <li>
                                            <label>Loose Load</label>
                                            <div className="text">{checkBooleanValues(item.isLooseLoad)}</div>
                                        </li>
                                    </ul>
                                ))
                            ) : (
                                <div className="no-record-text">
                                    {CommonConstants.NoRecordsAvailable}
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default FreightDetails;
