import { processErrorResponse, processResponse } from '../helpers';
import { deleteAsync, getAsync, postAsync, putAsync } from './apiClient';

export const getRecord = async (inputURL) => {
    try {
        const { REACT_APP_API_GATEWAY } = process.env;
        const response = await getAsync(`${REACT_APP_API_GATEWAY}/${inputURL}`);
        return processResponse(response);
    }
    catch (e) {
        return processErrorResponse(e);
    }
}

export const postRecord = async (inputURL, inputData) => {
    try {
        const { REACT_APP_API_GATEWAY } = process.env;
        const response = await postAsync(`${REACT_APP_API_GATEWAY}/${inputURL}`, inputData);
        return processResponse(response);
    }
    catch (e) {
        return processErrorResponse(e);
    }
}

export const putRecord = async (inputURL, inputData) => {
    try {
        const { REACT_APP_API_GATEWAY } = process.env;
        const response = await putAsync(`${REACT_APP_API_GATEWAY}/${inputURL}`, inputData);
        return processResponse(response);
    }
    catch (e) {
        return processErrorResponse(e);
    }
}

export const deleteRecord = async (inputURL, payload) => {
    try {
        const { REACT_APP_API_GATEWAY } = process.env;
        const response = await deleteAsync(`${REACT_APP_API_GATEWAY}/${inputURL}`, payload);
        return processResponse(response);
    }
    catch (e) {
        return processErrorResponse(e);
    }
}
