export const CommonConstants = {
    appVersion: 'v1.1.6',
    origin: "origin",
    destination: "destination",
    vehicleView: 'vehicleView',
    nestedView: 'nestedView',
    shipmentViewTitle: 'Shipment View',
    vehicleViewTitle: 'Vehicle View',
    addForm: 'addForm',
    updateForm: 'updateForm',
    bidForm: 'bidForm',
    reBidForm: 'reBidForm',
    deleteNotification: 'Delete Notification',
    deleteAllNotifications: 'Delete All Notifications',
    recordDeletedSuccess: 'Record Deleted Successfully.',
    APIErrorMessage: 'Something went wrong, Try again.',
    serverErrorMessage: 'Internal server error.',
    sessionErrorMessage: 'Your session has timed out. Please login again.',
    permissionErrorMessage: 'You do not have permission to access this module.',
    settingPermissionErrorMessage: 'You do not have permission to access this setting.',
    gridsSearchErrorMessage: 'Requires a minimum of 3 characters to be entered ',
    deleteConformationMessage: 'Do you want to delete this record?',
    deleteAllNotificationMessage: 'Do you want to delete all the notifications?',
    loginErrorMessage: "Need Admin approval. You don't have permission to access the BID App Application. Please contact Admin.",
    reserveSuccessMessage: "Vehicle has been reserved successfully.",
    releaseSuccessMessage: "Vehicle hes been released successfully.",
    turndownSuccessMessage: "Vehicle has been turned Down successfully.",
    deleteNotificationSuccessMessage: 'All notifications has been deleted successfully by',
    deleteSelected: "Delete Selected",
    removeVehicles: "Remove Vehicles",
    loading: 'Loading...',
    NoRecordsAvailable: 'No records available',
    dataLoadingError: 'Data loading error. Please reload the page.',
    deliveryDateError: 'Delivery date should be equal or greater than pickup date',
    messageField: 'RedirectToLoginMessage',
    userLoggedOut: 'You are signed out successfully',
    userSignIn: 'Please sign in to access this application',
    userUnauthorized: 'You are not authorized to view this page. Please re-login',
    bidTimeout: 'Bid App - Bid Submission Timeout!. Please try again.',
    undoShipmentSuccess: 'Record Restored Successfully.',
    watchListed: 'Watchlisted',
    dimensionFormError: 'Please fill all the fields.',
    formErrorMessage: 'Please fill all the mandatory fields.'
}

export const DateTimeConstants = {
    dateTimeFormat: 'MM/DD/YYYY HH:mm:ss',
    gridDateFormat: 'MM/DD/YYYY HH:mm:ss z',
    usCanadaEasternTime: "America/New_York"
}

export const VehicleStateEventConstants = {
    reserveSuccess: "RESERVE_SUCCESS",
    releaseSuccess: "RELEASE_SUCCESS",
    turnDownSuccess: "TURNDOWN_SUCCESS",
    signalReserve: "SIGNAL_RESERVE",
    signalRelease: "SIGNAL_RELEASE",
    signalTurndown: "SIGNAL_TURNDOWN"
}

export const GridConstants = {
    aero: 'aero',
    XPOCustomer: 'xpo',
    activeAero: 'active aero'
}

export const BidFormConstants = {
    isFast: 'isFast',
    pickupDate: 'pickupDate',
    pickupTime: 'pickupTime',
    deliveryDate: 'deliveryDate',
    deliveryTime: 'deliveryTime',
    etaPickupDate: 'etaPickupDate',
    etaPickupTime: 'etaPickupTime',
    etaDeliveryDate: 'etaDeliveryDate',
    etaDeliveryTime: 'etaDeliveryTime',
    etaPickupDateTime: 'etaPickupDateTime',
    etaDeliveryDateTime: 'etaDeliveryDateTime',
    pickupDateField: 'pickupDateField',
    deliveryDateField: 'deliveryDateField',
    etaPickupDateField: 'etaPickupDateField',
    etaDeliveryDateField: 'etaDeliveryDateField',
    baseRate: 'baseRate',
    vehicleId: 'vehicleId',
    borderCrossings: 'borderCrossings',
    alternativeReason: 'alternativeReason',
    ETAPickupDateTimeError: 'ETA Pickup Time is required',
    ETADeliveryDateTimeError: 'ETA Delivery Time is required',
    baseRateRequiredError: 'Base Rate is required',
    baseRatePatternError: 'Please enter valid Base Rate',
    vehicleIdError: 'Vehicle Size is required',
    bidFormSuccess: 'Bid Submitted Successfully.',
    borderCrossingsRequiredError: 'Border Crossing is required',
    alternativeReasonError: 'Tariff Reason is required',
    pickupDateTimeError: 'Pickup date & time is required',
    deliveryDateTimeError: 'Delivery date & time is required',
    etaPickupDateTimeError: 'ETA Pickup date & time is required',
    etaDeliveryDateTimeError: 'ETA Delivery date & time is required',
    shipmentUpdateSuccess: 'Shipment Information Updated Successfully.',
}

export const DeclineFormConstants = {
    rejectReasonType: 'rejectReasonType',
    rejectReasonTypeError: 'Please Select Reason',
    declineFormSuccess: 'Decline Submitted Successfully.',
    placedTab: 'placedTab',
    internalReasonType: 'internalReasonType',
    internalReasonTypeError: 'Please select any one of internal reasons'
}

export const SettingsConstants = {
    vehicleList: 'vehicleList',
    vehicleReference: 'vehicleReference'
}

export const AwardFormConstants = {
    awardAcceptFormSuccess: 'Awarded Bid Accepted Successfully.',
    awardDeclineFormSuccess: 'Award Bid Declined Successfully.',
    acceptConformationMessage: 'Do you want to accept this award?',
    rejectConformationMessage: 'Do you want to reject this award?',
    proNumberAcceptanceSuccess: 'Award Accepted Successfully.'
}

export const StateMatrixConstants = {
    stateMatrixAddedSuccess: 'Settings Added Successfully.',
    stateMatrixUpdateSuccess: 'Settings Updated Successfully.',
    stateMatrixDeleteSuccess: 'Settings Removed Successfully.'
}

export const AwardStatusConstants = {
    pending: 'Pending',
    accepted: 'Accepted',
    declined: 'Declined',
    expired: 'Expired',
    awardTimeExpired: 'Award Time Expired'
}

export const GlobalSearchConstants = {
    autoNo: 'AutoNo',
    awarded: 'Awarded',
    declined: 'Declined',
    deleted: 'Deleted',
    expired: 'Expired',
    placed: 'Placed',
    new: 'New',
    nestedView: 'NestedView',
    truckView: 'TruckView',
    watchlist: 'Watchlist',
    bidMatches: 'bidMatches',
    watchListMatches: 'watchListMatches',
    counterOffer: 'CounterOffer',
    rejected: 'Rejected'
}

export const GeneralSettingConstants = {
    milesPerHour: 'milesPerHour',
    loadTime: 'loadTime',
    weight: 'weight',
    milesErrorField: 'milesErrorField',
    loadTimeErrorField: 'loadTimeErrorField',
    weightErrorField: 'weightErrorField',
    formSuccessMessage: 'Setting updated successfully',
    customerTagDuplidateErrorMessage: ' is already exist in customer tag.',
    customerTagDeleteMessage: 'Do you want to delete this '
}

export const WatchListConstants = {
    core: 'Core',
    addedSuccessMessage: 'Vehicle added to watchlist',
    deleteSuccessMessage: 'Vehicle removed from watchlist',
    deleteConformationMessage: 'Do you want to remove this vehicle from watchlist?',
}

export const UserFormConstants = {
    bidAppRoleUser: 'bidapp-role-user',
    bidAppRoleAdmin: 'bidapp-role-admin',
    id: 'id',
    roles: 'roles',
    userIdError: 'User is required',
    rolesError: 'Roles are required',
    updateSuccessMessage: 'User updated successfully',
    addedSuccessMessage: 'User added successfully',
    deleteSuccessMessage: 'User removed successfully',
    deleteConformationMessage: 'Are you sure you want to remove this user?',
}

export const CustomerFormConstants = {
    tmsId: 'tmsId',
    companyName: 'companyName',
    contactName: 'contactName',
    phoneNumber: 'phoneNumber',
    contactEmail: 'contactEmail',
    parsingKey: 'parsingKey',
    gridView: 'gridView',
    listView: 'listView',
    gridViewTile: 'Grid View',
    listViewTile: 'List View',
    updateSuccessMessage: 'Customer settings modified Successfully',
    addedSuccessMessage: 'Customer added successfully',
    deleteSuccessMessage: 'Customer removed successfully',
    deleteConformationMessage: 'Are you sure you want to remove this customer?',
    updateGeneralSettingsSuccessMessage: 'Setting updated Successfully',
    declineSuccessMessage: 'Decline reason added successfully',
    declineDeleteMessage: 'Decline reason removed successfully',
}

export const FormValidationConstants = {
    requiredField: 'This field is required',
    phoneNumber: 'Please enter valid phone number',
    emailAddress: 'Enter a valid email address'
}

export const TeamSizeConstants = {
    single: 'Single',
    team: 'Team',
    relay: 'Relay'
}

export const MatchesConstants = {
    noBid: 'No-Bid',
    yesBid: 'Yes-Bid',
    bestAlternative: 'Best Alternative'
}

export const SearchPlaceholderConstants = {
    searchByRefNo: 'Search by Ref No',
    searchByKeyword: 'Search by Keyword',
    bidMatches: 'Search by Unit Number',
    vehicleView: 'Search by Unit Number or Driver Name'
}

export const CommonReducerTypeConstants = {
    getLoggedUserData: 'GET_LOGGED_USER_DATA',
    getSideNavBarListData: 'GET_SIDE_NAVBAR_LIST_DATA',
    getBidAppUserList: 'GET_BID_APP_USER_LIST',
    getBorderCrossingList: 'GET_BORDER_CROSSING_LIST',
    getAlternativeReasonList: 'GET_ALTERNATIVE_REASON_LIST',
    getAllVehiclesWithLoadTimeList: 'GET_ALL_VEHICLES_WITH_LOAD_TIME_LIST',
    getAllVehiclesWithCustomerIdList: 'GET_ALL_VEHICLES_WITH_CUSTOMER_ID_LIST',
    setCountryList: 'SET_COUNTRY_LIST',
    setColumnData: 'SET_COLUMN_DATA',
    setCurrentShipmentDataForBidMatches: 'SET_CURRENT_SHIPMENT_DATA_FOR_BID_MATCHES'
}

export const NewShipmentReducerConstants = {
    setGridRecords: 'SET_NEW_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_NEW_GRID_TOTAL_COUNT',
    setSelectedNewShipmentId: 'SET_SELECTED_NEW_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_NEW_GRID',
    setBidFormActivityId: 'SET_BID_FORM_ACTIVITY_ID',
    setDisabledShipments: 'SET_DISABLED_SHIPMENTS',
    getCustomerFilterForNewTab: 'GET_CUSTOMER_FILTER_FOR_NEW_TAB',
    getCountryFilterForNewTab: 'GET_COUNTRY_FILTER_FOR_NEW_TAB',
    getOriginStateCountryFilterForNewTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_NEW_TAB',
    getDestinationStateCountryFilterForNewTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_NEW_TAB',
    resetAllFilterForNewTab: 'RESET_ALL_FILTER_FOR_NEW_TAB'
}

export const PlacedShipmentReducerConstants = {
    setGridRecords: 'SET_PLACED_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_PLACED_GRID_TOTAL_COUNT',
    setSelectedPlacedShipmentId: 'SET_SELECTED_PLACED_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_PLACED_GRID',
    setBidFormActivityId: 'SET_BID_FORM_ACTIVITY_ID',
    getDateRangeFilterForPlacedTab: 'GET_DATE_RANGE_FILTER_FOR_PLACED_TAB',
    getCustomerFilterForPlacedTab: 'GET_CUSTOMER_FILTER_FOR_PLACED_TAB',
    getOriginStateCountryFilterForPlacedTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_PLACED_TAB',
    getDestinationStateCountryFilterForPlacedTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_PLACED_TAB',
    getBidSentByFilterForPlacedTab: 'GET_BID_SENT_BY_FILTER_FOR_PLACED_TAB',
    resetAllFilterForPlacedTab: 'RESET_ALL_FILTER_FOR_PLACED_TAB'
}

export const AwardedShipmentReducerConstants = {
    setGridRecords: 'SET_AWARDED_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_AWARDED_GRID_TOTAL_COUNT',
    setSelectedAwardedShipmentId: 'SET_SELECTED_AWARDED_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_AWARDED_GRID',
    setGridRefresh: 'SET_AWARDED_GRID_REFRESH',
    getDateRangeFilterForAwardTab: 'GET_DATE_RANGE_FILTER_FOR_AWARD_TAB',
    getCustomerFilterForAwardTab: 'GET_CUSTOMER_FILTER_FOR_AWARD_TAB',
    getOriginStateCountryFilterForAwardTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_AWARD_TAB',
    getDestinationStateCountryFilterForAwardTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_AWARD_TAB',
    getVehicleSizeFilterForAwardTab: 'GET_VEHICLE_SIZE_FILTER_FOR_AWARD_TAB',
    getAwardedStatusFilterForAwardsTab: 'GET_AWARDED_STATUS_FILTER_FOR_AWARD_TAB',
    resetAllFilterForAwardsTab: 'RESET_ALL_FILTER_FOR_AWARDS_TAB'
}

export const CounterOfferShipmentReducerConstants = {
    setGridRecords: 'SET_COUNTER_OFFER_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_COUNTER_OFFER_GRID_TOTAL_COUNT',
    setSelectedCounterOfferShipmentId: 'SET_SELECTED_COUNTER_OFFER_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_COUNTER_OFFER_GRID',
    getDateRangeFilterForCounterOfferTab: 'GET_DATE_RANGE_FILTER_FOR_COUNTER_OFFER_TAB',
    getCustomerFilterForCounterOfferTab: 'GET_CUSTOMER_FILTER_FOR_COUNTER_OFFER_TAB',
    getOriginStateCountryFilterForCounterOfferTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_COUNTER_OFFER_TAB',
    getDestinationStateCountryFilterForCounterOfferTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_COUNTER_OFFER_TAB',
    getStatusFilterForCounterOfferTab: 'GET_STATUS_FILTER_FOR_COUNTER_OFFER_TAB',
    resetAllFilterForCounterOfferTab: 'RESET_ALL_FILTER_FOR_COUNTER_OFFER_TAB'
}

export const RejectedShipmentReducerConstants = {
    setGridRecords: 'SET_REJECTED_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_REJECTED_GRID_TOTAL_COUNT',
    setSelectedRejectedShipmentId: 'SET_SELECTED_REJECTED_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_REJECTED_GRID',
    getDateRangeFilterForRejectedTab: 'GET_DATE_RANGE_FILTER_FOR_REJECTED_TAB',
    getCustomerFilterForRejectedTab: 'GET_CUSTOMER_FILTER_FOR_REJECTED_TAB',
    getOriginStateCountryFilterForRejectedTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_REJECTED_TAB',
    getDestinationStateCountryFilterForRejectedTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_REJECTED_TAB',
    getRejectedReasonFilterForRejectedTab: 'GET_REJECTED_REASON_FILTER_FOR_REJECTED_TAB',
    resetAllFilterForRejectedTab: 'RESET_ALL_FILTER_FOR_REJECTED_TAB'
}

export const AutoNoShipmentReducerConstants = {
    setGridRecords: 'SET_AUTONO_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_AUTONO_GRID_TOTAL_COUNT',
    setSelectedAutoNoShipmentId: 'SET_SELECTED_AUTO_NO_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_AUTO_NO_GRID',
    getDateRangeFilterForAutoNoTab: 'GET_DATE_RANGE_FILTER_FOR_AUTO_NO_TAB',
    getCustomerFilterForAutoNoTab: 'GET_CUSTOMER_FILTER_FOR_AUTO_NO_TAB',
    getOriginStateCountryFilterForAutoNoTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_AUTO_NO_TAB',
    getDestinationStateCountryFilterForAutoNoTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_AUTO_NO_TAB',
    getAutoNoReasonFilterForAutoNoTab: 'GET_AUTO_NO_REASON_FILTER_FOR_AUTO_NO_TAB',
    resetAllFilterForAutoNoTab: 'RESET_ALL_FILTER_FOR_AUTONO_TAB'
}

export const DeclinedShipmentReducerConstants = {
    setGridRecords: 'SET_DECLINED_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_DECLINED_GRID_TOTAL_COUNT',
    setSelectedDeclineShipmentId: 'SET_SELECTED_DECLINED_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_DECLINED_GRID',
    setBidFormActivityId: 'SET_BID_FORM_ACTIVITY_ID',
    getDateRangeFilterForDeclinedTab: 'GET_DATE_RANGE_FILTER_FOR_DECLINED_TAB',
    getCustomerFilterForDeclinedTab: 'GET_CUSTOMER_FILTER_FOR_DECLINED_TAB',
    getOriginStateCountryFilterForDeclinedTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_DECLINED_TAB',
    getDestinationStateCountryFilterForDeclinedTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_DECLINED_TAB',
    getDeclinedByFilterForDeclinedTab: 'GET_DECLINED_BY_FILTER_FOR_DECLINED_TAB',
    resetAllFilterForDeclinedTab: 'RESET_ALL_FILTER_FOR_DECLINED_TAB'
}

export const DeletedShipmentReducerConstants = {
    setGridRecords: 'SET_DELETED_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_DELETED_GRID_TOTAL_COUNT',
    setSelectedDeletedShipmentId: 'SET_SELECTED_DELETED_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_DELETED_GRID',
    getDateRangeFilterForDeletedTab: 'GET_DATE_RANGE_FILTER_FOR_DELETED_TAB',
    getCustomerFilterForDeletedTab: 'GET_CUSTOMER_FILTER_FOR_DELETED_TAB',
    getOriginStateCountryFilterForDeletedTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_DELETED_TAB',
    getDestinationStateCountryFilterForDeletedTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_DELETED_TAB',
    getDeletedByFilterForDeletedTab: 'GET_DELETED_BY_FILTER_FOR_DELETED_TAB',
    resetAllFilterForDeletedTab: 'RESET_ALL_FILTER_FOR_DELETED_TAB'
}

export const ExpiredShipmentReducerConstants = {
    setGridRecords: 'SET_EXPIRED_SHIPMENT_GRID_RECORDS',
    setGridTotalCount: 'SET_EXPIRED_GRID_TOTAL_COUNT',
    setSelectedExpiredShipmentId: 'SET_SELECTED_EXPIRED_SHIPMENT_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_EXPIRED_GRID',
    setBidFormActivityId: 'SET_BID_FORM_ACTIVITY_ID',
    getDateRangeFilterForExpiredTab: 'GET_DATE_RANGE_FILTER_FOR_EXPIRED_TAB',
    getCustomerFilterForExpiredTab: 'GET_CUSTOMER_FILTER_FOR_EXPIRED_TAB',
    getOriginStateCountryFilterForExpiredTab: 'GET_ORIGIN_STATE_COUNTRY_FILTER_FOR_EXPIRED_TAB',
    getDestinationStateCountryFilterForExpiredTab: 'GET_DESTINATION_STATE_COUNTRY_FILTER_FOR_EXPIRED_TAB',
    resetAllFilterForExpiredTab: 'RESET_ALL_FILTER_FOR_EXPIRED_TAB'
}

export const VehicleViewReducerConstants = {
    setGridRecords: 'SET_VEHICLE_VIEW_GRID_RECORDS',
    setGridTotalCount: 'SET_VEHICLE_VIEW_GRID_TOTAL_COUNT',
    setSelectedVehicleId: 'SET_SELECTED_VEHICLE_VIEW_ID',
    setSelectedRowFromGrid: 'SET_SELECTED_ROW_FROM_VEHICLE_VIEW_GRID',
    setLocationFilterForTruckView: 'SET_LOCATION_FILTER_FOR_TRUCK_VIEW',
    setStatusFilterForTruckView: 'SET_STATUS_FILTER_FOR_TRUCK_VIEW',
    setCoreFilterForTruckView: 'SET_CORE_FILTER_FOR_TRUCK_VIEW',
    setLastLoadedFilterForTruckView: 'SET_LAST_LOADED_FILTER_FOR_TRUCK_VIEW',
    setLastAvailableFilterForTruckView: 'SET_LAST_AVAILABLE_FILTER_FOR_TRUCK_VIEW',
    resetAllFilterForTruckList: 'SET_RESET_ALL_FILTER_FOR_TRUCK_LIST',
}

export const ReducerTypeConstants = {
    getCustomerFilterForBidMonitor: 'GET_CUSTOMER_FILTER_FOR_BID_MONITOR',
    getCountryFilterForBidMonitor: 'GET_COUNTRY_FILTER_FOR_BID_MONITOR',
    getStateCountryFilterForBidMonitor: 'GET_STATE_COUNTRY_FILTER_FOR_BID_MONITOR',
    getDateRangeFilterForBidMonitor: 'GET_DATE_RANGE_FILTER_FOR_BID_MONITOR',
    getUsersFilterForBidMonitor: 'GET_USERS_FILTER_FOR_BID_MONITOR',
    getAwardedStatusFilterForBidMonitor: 'GET_AWARDED_STATUS_FILTER_FOR_BID_MONITOR',
    getVehicleFilterForBidMonitor: 'GET_VEHICLE_FILTER_FOR_BID_MONITOR',
    getAutoNoReasonFilterForBidMonitor: 'GET_AUTO_REASON_FILTER_FOR_BID_MONITOR',
    getVehicleStatusFilterForBidMonitor: 'GET_VEHICLE_STATUS_FILTER_FOR_BID_MONITOR',
    getCoreStatesForBidMonitor: 'GET_CORE_STATES_FOR_BID_MONITOR',
    resetAllFilterForWatchList: 'SET_RESET_ALL_FILTER_FOR_WATCH_LIST',
    resetAllFilterForBidMatches: "SET_RESET_ALL_FILTER_FOR_BID_MATCHES",
    resetAllFilterForWatchListMatches: 'SET_RESET_ALL_FILTER_FOR_WATCH_LIST_MATCHES',
    setVehicleSizeFilterForWatchList: 'SET_VEHICLE_SIZE_FILTER_FOR_WATCH_LIST',
    setStatusFilterForWATCHLIST: 'SET_STATUS_FILTER_FOR_WATCH_LIST',
    setCoreFilterForWatchList: 'SET_CORE_FILTER_FOR_WATCH_LIST',
    setLocationFilterForWatchList: 'SET_LOCATION_FILTER_FOR_WATCH_LIST',
    setRadiusFilterForWatchListMatches: 'SET_RADIUS_FILTER_FOR_WATCH_LIST_MATCHES',
    setSortByFilterForWatchListMatches: 'SET_SORTBY_FILTER_FOR_WATCH_LIST_MATCHES',
    setOriginFilterForWatchListMatches: "SET_ORIGIN_FILTER_FOR_WATCH_LIST_MATCHES",
    setDestinationFilterForWatchListMatches: "SET_DESTINATION_FILTER_FOR_WATCH_LIST_MATCHES",
    setRadiusFilterForbidMatches: 'SET_RADIUS_FILTER_FOR_BID_MATCHES',
    setShowExcludedFilterForbidMatches: 'SET_SHOW_EXCLUDED_FILTER_FOR_BID_MATCHES',
    setShowExcludedFilterForwatchlistMatches: 'SET_SHOW_EXCLUDED_FILTER_FOR_WATCHLIST_MATCHES',
    setMatchTypeFilterForbidMatches: 'SET_MATCH_TYPE_FILTER_FOR_BID_MATCHES',
    setVehicleSizeFilterForbidMatches: 'SET_VEHICLE_SIZE_FILTER_FOR_BID_MATCHES',
    getCoreStatesForBidMatches: 'GET_CORE_STATES_FOR_BID_MATCHES',
    getAllCustomerTags: 'GET_ALL_CUSTOMER_TAGS',
    getAllUserRoleList: 'GET_ALL_USER_ROLE_LIST',
    getAllUserList: 'GET_ALL_USER_LIST',
    setSelectedRowBidMatchesList: 'SET_SELECTED_ROW_BID_MATCHES_LIST',
    setSelectedRowWatchListList: 'SET_SELECTED_ROW_WATCH_LIST',
    setSelectedRowWatchListMatchesList: 'SET_SELECTED_ROW_NESTED_LIST_MATCHES_LIST',
    setSelectedRowBidFormVehicleMatchesList: 'SET_SELECTED_ROW_BID_FORM_VEHICLE_MATCHES_LIST',
    getCounterOfferStatusFilterForBidMonitor: 'GET_COUNTER_OFFER_STATUS_FILTER_FOR_BID_MONITOR',
    getRejectedReasonFilterForBidMonitor: 'GET_REJECTED_REASON_FILTER_FOR_BID_MONITOR',
}

export const GridNameConstants = {
    nestedList: 'nestedList',
    expiredList: 'expiredList',
    awardList: 'awardList',
    watchList: 'watchList',
    autoNoList: 'autoNoList',
    truckList: 'truckList',
    placedList: 'placedList',
    declinedList: 'declinedList',
    deletedList: 'deletedList',
    bidMatchesList: 'bidMatchesList',
    watchListMatchesList: 'watchListMatchesList',
    bidFormVehicleMatchesList: 'bidFormVehicleMatchesList',
    counterOfferList: 'counterOfferList',
    rejectedList: 'rejectedList'
}

export const DashboardConstants = {
    today: 'Today',
    yesterday: 'Yesterday',
    todayValue: 'todayValue',
    yesterdayValue: 'yesterdayValue'
}

export const TMSIdConstants = {
    xpo: 92923,
    activeAero: 126832
}

export const UpdateShipmentLink = 'https://www.expedite.rxo.com/WebClient/ShipmentDetails/#/ShipmentDetails/';

export const SystemSettingsFormConstants = {
    emailAddress: 'emailAddress',
    updateSuccessMessage: 'Setting updated Successfully',
}

export const BidTypeConstant = {
    yesBid: "Yes Bid",
    pBid: "P Bid"
}

export const CustomerReasonConstants = {
    noDriversAvailable: "No Drivers Available",
    noUnitsAvailable: "No Units Available"
}

export const PlacedFormConstants = {
    successMessage: "Bid Awarded Successfully."
}

export const ProFormConstants = {
    proNumber: 'Enter a valid PRO Number',
    successMessage: "PRO Number Added Successfully."
}

export const CounterOfferConstants = {
    acceptSuccessMessage: 'Counter Offer Accepted Successfully.',
    rejectSuccessMessage: 'Counter Offer Rejected Successfully.'
}

export const AcceptBidFormConstants = {
    successMessage: "Bid Accepted Successfully."
}

export const RejectFormConstants = {
    rejectReason: 'Select Reject Reason',
    successMessage: "Bid Rejected Successfully."
}

export const CounterFormConstants = {
    counterRate: 'Enter a valid Counter Rate',
    successMessage: "Counter Offer Placed Successfully."
}

export const RateFormConstants = {
    originCity: 'originCity',
    originState: 'originState',
    destinationCity: 'destinationCity',
    destinationState: 'destinationState',
    shipDate: 'shipDate',
    shipTime: 'shipTime',
    vehicleSize: 'vehicleSize',
    totalWeight: 'totalWeight',
    totalMiles: 'totalMiles',
    predictionRate: 'Prediction Rate',
    minPredictionRate: 'Min Prediction Rate',
    maxPredictionRate: 'Max Prediction Rate',
    successMessage: 'Rate Prediction done Successfully.'
}

export const ThemeSettingConstants = {
    liteThemeTurnOnMessage: 'Light Mode Theme Turned ON.',
    darkThemeTurnOnMessage: "Dark Mode Theme Turned ON."
}
