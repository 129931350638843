/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { AcceptBidFormConstants, FormValidationConstants } from "../../../app-core/constants/common-constants";
import { postAcceptEmailShipment } from "../../../app-core/services/common-service";
import { toastError, toastSuccess } from "../../../app-core/services/toast-service";
import FormLoader from "../form-loader/form-loader";
import './accept-form.scss';

const AcceptForm = (props) => {

    const [acceptedBy, setAcceptedBy] = useState('');
    const [acceptFieldValidate, setAcceptFieldValidate] = useState('');
    const [showAcceptForm, setShowAcceptForm] = useState(false);
    const [showFormLoader, setShowFormLoader] = useState(false);

    useEffect(() => {
        if (showAcceptForm !== props.acceptFormProps.showAcceptForm) {
            setShowAcceptForm(props.acceptFormProps.showAcceptForm);
        }
    }, [props]);

    const onFormSubmit = (event) => {
        event.preventDefault();
        const formValid = getFormValidation();
        if (formValid) {
            setShowFormLoader(true);
            postAcceptEmailShipment({ quoteAcceptedBy: acceptedBy }).then(response => {
                try {
                    if (response.data.isSuccess) {
                        props.parentCallback(true);
                        toastSuccess(AcceptBidFormConstants.successMessage);
                        onResetForm();
                    } else {
                        toastError(response.data?.errorMessage);
                        setShowFormLoader(false);
                    }
                } catch (error) {
                    setShowFormLoader(false);
                }
            });
        }
    }

    const getFormValidation = () => {
        const validation = onFormValidate('acceptedBy', acceptedBy);
        setAcceptFieldValidate(validation);
        return validation ? false : true;
    }

    const onFormValidate = (name, value) => {
        switch (name) {
            case 'acceptedBy':
                if (!value) {
                    return FormValidationConstants.requiredField;
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    const onHandleUserInput = (event) => {
        setAcceptedBy(event.target.value);
        setAcceptFieldValidate(onFormValidate(event.target.name, event.target.value));
    }

    const onCloseForm = () => {
        onResetForm();
        setShowAcceptForm(false);
        props.parentCallback(false);
    }

    const onResetForm = () => {
        setTimeout(() => {
            setAcceptedBy('');
            setAcceptFieldValidate('');
            setShowAcceptForm(false);
            setShowFormLoader(false);
        }, 500);
    }

    return (
        <div>
            <Dialog open={showAcceptForm} className="accept-form-wrapper dialog-wrapper" maxWidth="sm">
                <DialogTitle>
                    <span>Quote Accepted by</span>
                    <Tooltip title="Close">
                        <IconButton size="small" className="close-btn" onClick={onCloseForm}>
                            <span className="material-icons">close</span>
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <form onSubmit={onFormSubmit} noValidate autoComplete="off" className="position-relative">
                    {
                        showFormLoader &&
                        <FormLoader showFormLoader={showFormLoader}></FormLoader>
                    }
                    <DialogContent>
                        <div className="row">
                            <div className="form-group">
                                <label className="form-label">Accepted By <sup>*</sup></label>
                                <TextField
                                    required
                                    name="acceptedBy"
                                    value={acceptedBy}
                                    variant="outlined"
                                    onChange={onHandleUserInput}
                                    inputProps={{ maxLength: 50 }}
                                    error={acceptFieldValidate ? true : false} />
                                <span className="error-message">{acceptFieldValidate}</span>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" variant="outlined" color="primary" onClick={onCloseForm}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default AcceptForm;
