import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastConfig = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: true,
    rtl: false,
    pauseOnHover: true,
    draggable: false,
    theme: 'dark'
}

export const toastSuccess = (message) => {
    toast.success(message, toastConfig);
}

export const toastError = (message) => {
    toast.error(message, toastConfig);
}

export const toastWarning = (message) => {
    toast.warning(message, toastConfig);
}

export const toastInfo = (message) => {
    toast.info(message, toastConfig);
}