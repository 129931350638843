/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import * as _ from 'lodash';
import { useEffect, useState } from "react";
import { FormValidationConstants, RejectFormConstants } from "../../../app-core/constants/common-constants";
import { RejectFormErrorFields, RejectFormPayload, RejectReasonsSourceList } from "../../../app-core/data-model/common-data-model";
import { objectMapping } from "../../../app-core/helpers";
import { postRejectForm } from "../../../app-core/services/common-service";
import { toastError, toastSuccess } from "../../../app-core/services/toast-service";
import FormLoader from "../form-loader/form-loader";
import './reject-form.scss';

const RejectForm = (props) => {

    const [showRejectForm, setShowRejectForm] = useState(false);
    const [showFormLoader, setShowFormLoader] = useState(false);
    const [rejectReasonSelectedValue, setRejectReasonSelectedValue] = useState(null);
    const [rejectFormPayload, setRejectFormPayload] = useState(Object.assign({}, RejectFormPayload));
    const [rejectFormErrorFields, setRejectFormErrorFields] = useState(Object.assign({}, RejectFormErrorFields));

    useEffect(() => {
        if (showRejectForm !== props.rejectFormProps.showRejectForm) {
            setShowRejectForm(props.rejectFormProps.showRejectForm);
        }
    }, [props]);

    const onFormSubmit = (event) => {
        event.preventDefault();
        const formValid = getFormValidation();
        if (formValid) {
            setShowFormLoader(true);
            postRejectForm(rejectFormPayload).then(response => {
                try {
                    if (response.data?.isSuccess) {
                        props.parentCallback(true);
                        toastSuccess(RejectFormConstants.successMessage);
                        onResetForm();
                    } else {
                        toastError(response.data.errorMessage);
                        setShowFormLoader(false);
                    }
                } catch (error) {
                    setShowFormLoader(false);
                }
            });
        }
    }

    const getFormValidation = () => {
        let validationErrors = {};
        Object.keys(rejectFormErrorFields).forEach(name => {
            if (rejectFormErrorFields[name]) {
                validationErrors[name] = rejectFormErrorFields[name];
            }
        });
        Object.keys(rejectFormPayload).forEach(name => {
            const error = onFormValidate(name, rejectFormPayload[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            const formFields = _.cloneDeep(rejectFormErrorFields);
            objectMapping(validationErrors, formFields);
            setRejectFormErrorFields(formFields);
            return false;
        }
        return true;
    }

    const onFormValidate = (name, value) => {
        switch (name) {
            case 'rejectReasonType':
                if (!value) {
                    return RejectFormConstants.rejectReason;
                } else {
                    return "";
                }
            case 'quoteRejectedBy':
                if (!value) {
                    return FormValidationConstants.requiredField
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    const onHandleUserInput = (event) => {
        setRejectFormPayload({
            ...rejectFormPayload,
            [event.target.name]: event.target.value
        });
        setRejectFormErrorFields({
            ...rejectFormErrorFields,
            [event.target.name]: onFormValidate(event.target.name, event.target.value)
        });
    }

    const onSelectReason = (data) => {
        const value = data?.value || null;
        setRejectFormPayload({
            ...rejectFormPayload,
            rejectReasonType: value
        });
        setRejectFormErrorFields({
            ...rejectFormErrorFields,
            rejectReasonType: onFormValidate('rejectReasonType', value)
        });
        setRejectReasonSelectedValue(data || null);
    }

    const onCloseForm = () => {
        onResetForm();
        props.parentCallback(false);
    }

    const onResetForm = () => {
        setShowRejectForm(false);
        setShowFormLoader(false);
        setRejectReasonSelectedValue(null);
        setRejectFormPayload(Object.assign({}, RejectFormPayload));
        setRejectFormErrorFields(Object.assign({}, RejectFormErrorFields));
    }

    return (
        <div>
            <Dialog open={showRejectForm} className="reject-form-wrapper dialog-wrapper" maxWidth="sm">
                <DialogTitle>
                    <span>Reject Offer</span>
                    <Tooltip title="Close">
                        <IconButton size="small" className="close-btn" onClick={onCloseForm}>
                            <span className="material-icons">close</span>
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <form onSubmit={onFormSubmit} noValidate autoComplete="off" className="position-relative">
                    {
                        showFormLoader &&
                        <FormLoader showFormLoader={showFormLoader}></FormLoader>
                    }
                    <DialogContent>
                        <div className="row">
                            <div className="form-group">
                                <label className="form-label">Rejected By <sup>*</sup></label>
                                <TextField
                                    required
                                    name="quoteRejectedBy"
                                    value={rejectFormPayload.quoteRejectedBy}
                                    variant="outlined"
                                    onChange={onHandleUserInput}
                                    inputProps={{ maxLength: 50 }}
                                    error={rejectFormErrorFields?.quoteRejectedBy ? true : false} />
                                <span className="error-message">{rejectFormErrorFields?.quoteRejectedBy}</span>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Select Reject Reason <sup>*</sup></label>
                                <div className="auto-complete-control">
                                    <Autocomplete
                                        name="rejectReasonType"
                                        value={rejectReasonSelectedValue}
                                        options={_.cloneDeep(RejectReasonsSourceList)}
                                        getOptionLabel={(option) => option.text || ''}
                                        renderInput={(params) => <TextField error={rejectFormErrorFields?.rejectReasonType ? true : false} {...params} placeholder="Select" />}
                                        onChange={(e, newValue) => { onSelectReason(newValue) }}>
                                    </Autocomplete>
                                </div>
                                <span className="error-message">{rejectFormErrorFields?.rejectReasonType}</span>
                            </div>
                            <div className="form-group mb-2">
                                <label className="form-label">Notes</label>
                                <TextField
                                    multiline
                                    rows={4}
                                    name="notes"
                                    placeholder="Notes"
                                    variant="outlined"
                                    value={rejectFormPayload.notes}
                                    onChange={onHandleUserInput}
                                    inputProps={{ maxLength: 500 }} />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" variant="outlined" color="primary" onClick={onCloseForm}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default RejectForm;
