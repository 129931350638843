export const ShipmentStepperEnum = {
    new: 1,
    placed: 2,
    awarded: 3,
    declined: 4,
    expired: 5
}

export const ExpirationTimerEnum = {
    success: 600000, // 10 Min
    warning: 300000, // 5 min 
    error: 180000 // 3 Min
}

export const GridStatusEnum = {
    autoNo: 2,
    nested: 3,
    placed: 4,
    expired: 5,
    declined: 6,
    awarded: 7,
    deleted: 9,
    counterOffer: 10,
    rejected: 11
}
