import axios from 'axios';
import { getToken } from '../helpers/common-method';
import { redirectToLogin } from '../helpers';

const { REACT_APP_API_GATEWAY } = process.env;

const axiosClient = axios.create({
    baseURL: REACT_APP_API_GATEWAY,
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosClient.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.apiKey = token ? token : null;
    } else {
        redirectToLogin();
        console.error('Unable to fetch token response!!!');
    }
    return config;
});

export default axiosClient;