/* eslint-disable no-useless-concat */
import moment from 'moment';
import 'moment-timezone';
import { DateTimeConstants, GridConstants } from '../constants/common-constants';
import { history } from './history';

export const redirectToLogin = () => {
    history.push('/page-not-found');
}

export const getUsCanadaEasternDateTime = (date) => {
    if (date) {
        var d = moment(convertUTCToLocal(new Date(date)));
        return d.tz(DateTimeConstants.usCanadaEasternTime).format(DateTimeConstants.gridDateFormat);
    }
    return 'N/A';
}

export const convertUTCToLocal = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export const convertLocalDateToUTCDate = (date) => {
    return moment.utc(moment(date)).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
}

export const convertUTCDateTimeToESTDateTime = (date) => {
    if (date) {
        var d = moment(convertUTCToLocal(new Date(date)));
        return d.tz(DateTimeConstants.usCanadaEasternTime).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
    }
    return 'N/A';
}

export const getESTDateTimeNow = () => {
    var d = moment(new Date());
    return d.tz(DateTimeConstants.usCanadaEasternTime).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss');
}

export const getEstDate = (date) => {
    if (date && date instanceof Date) {
        var d = moment(date);
        return d.tz(DateTimeConstants.usCanadaEasternTime).format('MM/DD/YYYY 00:00:00');
    }
}

export const getAddingDateTime = (date, minutes, seconds) => {
    return moment(date, "YYYY-MM-DD" + 'T' + "HH:mm:ss")
        .add(seconds, 'seconds')
        .add(minutes, 'minutes')
        .format();
}

export const convertDateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD');
}

export const convertTimeFormat = (date) => {
    if (date) {
        return moment(date).format('HH:mm:ss');
    }
}

export const convertTimeWithoutSeconds = (date) => {
    if (date) {
        return moment(date).format('HH:mm');
    }
}

export const convert12HrTimeFormat = (date) => {
    return moment(date).format('HH:mm:ss A');
}

export const getConvertedDateFormat = (date, time) => {
    return (moment(date).format('YYYY-MM-DD') + `${'T'}` + moment(time).format('HH:mm'));
}

export const getDateText = (data) => {
    if (data) {
        const estDateTime = convertUTCToLocal(new Date(data));
        return moment(estDateTime).fromNow();
    }
    return 'N/A';
}

export const convertDateAndTimeWithEST = (date) => {
    if (date) {
        return (moment(date).format(DateTimeConstants.dateTimeFormat) + ' EDT');
    }
}

export const getUtcDateTimeFromUsCanadaEasternDateTime = (date) => {
    const dateValue = new Date(date);
    var inputDateTimeAsEst = (dateValue.getMonth() + 1) + '/' + dateValue.getDate() + '/' + dateValue.getFullYear() + ' '
        + dateValue.getHours() + ':' + dateValue.getMinutes() + ':' + dateValue.getSeconds();
    var d = moment.tz(inputDateTimeAsEst, DateTimeConstants.dateTimeFormat, DateTimeConstants.usCanadaEasternTime);
    return d.utc().format('YYYY-MM-DD' + `${'T'}` + 'HH:mm:ss');
}

export const getDayFromDate = (date) => {
    var fromNow = moment(date).fromNow();
    // ensure the date is displayed with today and yesterday
    return moment(date).calendar(null, {
        // when the date is closer, specify custom values
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        // when the date is further away, use from-now functionality             
        sameElse: function () {
            return "[" + fromNow + "]";
        }
    });
}

export const checkExpiredDateValidation = (fromDate, customerName = false) => {
    let isDateExpired = false;
    const fromDateObject = new Date(convertUTCDateTimeToESTDateTime(fromDate));
    const currentESTDateObject = new Date(getESTDateTimeNow());
    if (fromDateObject && currentESTDateObject) {
        const difference = fromDateObject.getTime() - currentESTDateObject.getTime();
        if (customerName) {
            if (difference <= 0) {
                isDateExpired = (customerName.toLowerCase() === GridConstants.XPOCustomer ? true : false);
            }
        } else if (difference <= 0) {
            isDateExpired = true;
        } else {
            isDateExpired = false;
        }
    }
    return isDateExpired;
}
