import { getRecord, postRecord } from './api-common-service';

export const getEmailShipmentDetails = () => {
    return getRecord(`GetShipmentInfo`);
}

export const postAcceptEmailShipment = (inputData) => {
    return postRecord(`AcceptBidFromEmailLink`, inputData);
}

export const postRejectForm = (inputData) => {
    return postRecord(`RejectBidFromEmailLink`, inputData);
}

export const postCounterForm = (inputData) => {
    return postRecord(`SubmitCounterOffer`, inputData);
}
