/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import * as _ from 'lodash';
import { useEffect, useState } from "react";
import { CounterFormConstants, FormValidationConstants } from "../../../app-core/constants/common-constants";
import { CounterFormErrorFields, CounterFormPayload } from "../../../app-core/data-model/common-data-model";
import { objectMapping } from "../../../app-core/helpers";
import { CustomPatterns } from "../../../app-core/pattern/custom-patterns";
import { postCounterForm } from "../../../app-core/services/common-service";
import { toastError, toastSuccess } from "../../../app-core/services/toast-service";
import FormLoader from "../form-loader/form-loader";
import './counter-offer-form.scss';

const CounterOfferForm = (props) => {

    const [shipmentId, setShipmentId] = useState('');
    const [showCounterForm, setShowCounterForm] = useState(false);
    const [showFormLoader, setShowFormLoader] = useState(false);
    const [counterFormPayload, setCounterFormPayload] = useState(Object.assign({}, CounterFormPayload));
    const [counterFormErrorFields, setCounterFormErrorFields] = useState(Object.assign({}, CounterFormErrorFields));

    useEffect(() => {
        if (showCounterForm !== props.counterFormProps.showCounterForm) {
            setShowCounterForm(props.counterFormProps.showCounterForm);
            setShipmentId(props.counterFormProps.shipmentId);
        }
    }, [props]);

    const onFormSubmit = (event) => {
        event.preventDefault();
        const formValid = getFormValidation();
        if (formValid) {
            setShowFormLoader(true);
            const payload = {
                counterRate: parseFloat(counterFormPayload.counterRate),
                counterOfferPlacedBy: counterFormPayload.counterOfferPlacedBy
            }
            postCounterForm(payload).then(response => {
                try {
                    if (response.data.isSuccess) {
                        payload.isRefreshGrid = true;
                        props.parentCallback(payload);
                        toastSuccess(CounterFormConstants.successMessage);
                        onResetForm();
                    } else {
                        toastError(response.data.errorMessage);
                        setShowFormLoader(false);
                    }
                } catch (error) {
                    setShowFormLoader(false);
                }
            });
        }
    }

    const getFormValidation = () => {
        let validationErrors = {};
        Object.keys(counterFormErrorFields).forEach(name => {
            if (counterFormErrorFields[name]) {
                validationErrors[name] = counterFormErrorFields[name];
            }
        });
        Object.keys(counterFormPayload).forEach(name => {
            const error = onFormValidate(name, counterFormPayload[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            const formFields = _.cloneDeep(counterFormErrorFields);
            objectMapping(validationErrors, formFields);
            setCounterFormErrorFields(formFields);
            return false;
        }
        return true;
    }

    const onFormValidate = (name, value) => {
        switch (name) {
            case 'counterRate':
                if (!value) {
                    return FormValidationConstants.requiredField;
                } else if (!value.match(CustomPatterns.dimensionPattern)) {
                    return CounterFormConstants.counterRate;
                } else {
                    return "";
                }
            case 'counterOfferPlacedBy':
                if (!value) {
                    return FormValidationConstants.requiredField;
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    const onHandleUserInput = (event) => {
        setCounterFormPayload({
            ...counterFormPayload,
            [event.target.name]: event.target.value
        });
        setCounterFormErrorFields({
            ...counterFormErrorFields,
            [event.target.name]: onFormValidate(event.target.name, event.target.value)
        });
    }

    const onCloseForm = () => {
        onResetForm();
        setShowCounterForm(false);
        props.parentCallback(false);
    }

    const onResetForm = () => {
        setTimeout(() => {
            setShipmentId('');
            setShowCounterForm(false);
            setShowFormLoader(false);
            setCounterFormPayload(Object.assign({}, CounterFormPayload));
            setCounterFormErrorFields(Object.assign({}, CounterFormErrorFields));
        }, 500);
    }

    return (
        <div>
            <Dialog open={showCounterForm} className="counter-form-wrapper dialog-wrapper" maxWidth="sm">
                <DialogTitle>
                    <span>Enter Counter Rate</span>
                    <Tooltip title="Close">
                        <IconButton size="small" className="close-btn" onClick={onCloseForm}>
                            <span className="material-icons">close</span>
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <form onSubmit={onFormSubmit} noValidate autoComplete="off" className="position-relative">
                    {
                        showFormLoader &&
                        <FormLoader showFormLoader={showFormLoader}></FormLoader>
                    }
                    <DialogContent>
                        <div className="row">
                            <div className="form-group">
                                <label className="form-label">Counter Rate<sup>*</sup></label>
                                <TextField
                                    required
                                    name="counterRate"
                                    value={counterFormPayload.counterRate}
                                    variant="outlined"
                                    onChange={onHandleUserInput}
                                    inputProps={{ maxLength: 10 }}
                                    error={counterFormErrorFields?.counterRate ? true : false} />
                                <span className="error-message">{counterFormErrorFields?.counterRate}</span>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Placed By <sup>*</sup></label>
                                <TextField
                                    required
                                    name="counterOfferPlacedBy"
                                    value={counterFormPayload.counterOfferPlacedBy}
                                    variant="outlined"
                                    onChange={onHandleUserInput}
                                    inputProps={{ maxLength: 50 }}
                                    error={counterFormErrorFields?.counterOfferPlacedBy ? true : false} />
                                <span className="error-message">{counterFormErrorFields?.counterOfferPlacedBy}</span>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" variant="outlined" color="primary" onClick={onCloseForm}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default CounterOfferForm;
