/* eslint-disable no-unused-vars */
import { Route, Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { history } from "../app-core/helpers";
import '../assets/styles/material-modify.scss';
import './App.scss';
import EmailShipmentDetails from './email-shipment-details/email-shipment-details';
import PageNotFound from './page-not-found/page-not-found';

const App = () => {
    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={PageNotFound} />
                    <Route path="/page-not-found" component={PageNotFound} />
                    <Route path="/email-shipment-details" component={EmailShipmentDetails} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
            </Router>
            <ToastContainer />
        </>
    );
}

export default App;
