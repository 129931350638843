/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { CommonConstants } from "../../app-core/constants/common-constants";
import { AcceptFormProps, CounterFormProps, RejectFormProps, ShipmentDetailsModel } from "../../app-core/data-model/common-data-model";
import { ExpirationTimerEnum, GridStatusEnum } from "../../app-core/enums/common-enum";
import { convertUTCDateTimeToESTDateTime, getESTDateTimeNow, getUsCanadaEasternDateTime } from "../../app-core/helpers";
import { checkBooleanValues, checkCustomer, checkDisplayValue, checkNumericValue, convertToTwoDecimal, getExpiredCountDownTimer, getTMSIdForCustomer, mappingEmailShipmentData, setCustomerName, setToken, showFastShipment } from "../../app-core/helpers/common-method";
import { getEmailShipmentDetails } from "../../app-core/services/common-service";
import BoltExpressLogo from '../../assets/images/logo.png';
import AcceptForm from "../shared/accept-form/accept-form";
import CounterOfferForm from "../shared/counter-offer-form/counter-offer-form";
import FormLoader from "../shared/form-loader/form-loader";
import FreightDetails from "../shared/freight-details";
import RejectForm from "../shared/reject-form/reject-form";
import "./email-shipment-details.scss";

const EmailShipmentDetails = (props) => {

    const [showFormLoader, setShowFormLoader] = useState(false);
    const [tokenValue, setTokenValue] = useState('');
    const [isLinkExpired, setIsLinkExpired] = useState(false);
    const [isQuoteTimerExpired, setIsQuoteTimerExpired] = useState(false);
    const [isActionBtnDisabled, setIsActionBtnDisabled] = useState(false);
    const [expiredTimeDifference, setExpiredTimeDifference] = useState(null);
    const [expiredShipmentTimer, setExpiredShipmentTimer] = useState(null);
    const [acceptFormProps, setAcceptFormProps] = useState(Object.assign({}, AcceptFormProps));
    const [rejectFormProps, setRejectFormProps] = useState(Object.assign({}, RejectFormProps));
    const [counterFormProps, setCounterFormProps] = useState(Object.assign({}, CounterFormProps));
    const [shipmentDetailsModel, setShipmentDetailsModel] = useState(Object.assign({}, ShipmentDetailsModel));

    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token');
        setToken(token);
        setTokenValue(token);
    }, []);

    useEffect(() => {
        if (tokenValue) {
            onLoadItems();
        }
    }, [tokenValue]);

    useEffect(() => {
        if (shipmentDetailsModel?.shipmentNumber && !shipmentDetailsModel?.isExpired) {
            const interval = setInterval(() => {
                onShowExpireTimer();
            }, 1000);

            return () => {
                clearInterval(interval);
            }
        }
    }, [shipmentDetailsModel]);

    const onLoadItems = () => {
        setShowFormLoader(true);
        getEmailShipmentDetails().then(response => {
            try {
                if (response.data) {
                    if (response.data?.isExpired) {
                        setIsLinkExpired(response.data?.isExpired);
                    } else {
                        setIsActionBtnDisabled(response.data?.isClicked);
                        setShipmentDetailsModel(mappingEmailShipmentData(response.data));
                    }
                    setShowFormLoader(false);
                }
            } catch (error) {
                setShowFormLoader(false);
            }
        });
    }

    const onAcceptShipment = () => {
        setAcceptFormProps({
            ...acceptFormProps,
            showAcceptForm: true
        });
    }

    const onAcceptFormCallback = (data) => {
        if (data) {
            onLoadItems();
        }
        setAcceptFormProps({
            ...acceptFormProps,
            showAcceptForm: false
        });
    }

    const onRejectShipment = () => {
        setRejectFormProps({
            ...rejectFormProps,
            showRejectForm: true,
            token: tokenValue
        });
    }

    const onRejectFormCallback = (data) => {
        if (data) {
            onLoadItems();
        }
        setRejectFormProps({
            ...rejectFormProps,
            showRejectForm: false
        });
    }

    const onCounterOfferShipment = () => {
        setCounterFormProps({
            ...counterFormProps,
            showCounterForm: true,
            token: tokenValue
        });
    }

    const onCounterOfferFormCallback = (data) => {
        if (data) {
            onLoadItems();
        }
        setCounterFormProps({
            ...counterFormProps,
            showCounterForm: false
        });
    }

    const onShowExpireTimer = () => {
        const expireDateObject = new Date(convertUTCDateTimeToESTDateTime(shipmentDetailsModel?.linkExpiryDateTime));
        const currentESTDateObject = new Date(getESTDateTimeNow());

        if (expireDateObject && currentESTDateObject) {
            const difference = expireDateObject.getTime() - currentESTDateObject.getTime();

            if (difference <= 0) {
                setExpiredTimeDifference(null);
                setExpiredShipmentTimer(null);
                setIsLinkExpired(true);
            } else {
                let seconds = Math.floor(difference / 1000);
                let minutes = Math.floor(seconds / 60);
                let hours = Math.floor(minutes / 60);
                let days = Math.floor(hours / 24);

                hours %= 24;
                minutes %= 60;
                seconds %= 60;

                setExpiredTimeDifference(difference);
                setExpiredShipmentTimer(getExpiredCountDownTimer(days, hours, minutes, seconds));
                setIsQuoteTimerExpired(true);
            }
        }
    }

    return (
        <>
            <div className="email-shipment-wrapper">
                <FormLoader showFormLoader={showFormLoader}></FormLoader>
                {
                    isLinkExpired ? (
                        <>
                            <div className="email-header-wrapper">
                                <div className="row">
                                    <div className="col-6 p-0 d-flex align-items-center justify-content-start">
                                        <img src={BoltExpressLogo} className="logo" height="35" alt="logo" />
                                    </div>
                                    <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                    </div>
                                </div>
                            </div>
                            <div className="email-section-wrapper link-expired-wrapper">
                                <div className="link-expired">
                                    The Link is expired. Please contact Bolt Express Customer Support.
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="email-header-wrapper">
                                <div className="row">
                                    <div className="col-md-3 col-12 p-0 d-flex align-items-center justify-content-start">
                                        <img src={BoltExpressLogo} className="logo" height="35" alt="logo" />
                                    </div>
                                    <div className="col-md-9 col-12 p-0 header-flex-box">
                                        {
                                            expiredTimeDifference &&
                                            <span className="timer-section">
                                                {
                                                    expiredTimeDifference > ExpirationTimerEnum.warning &&
                                                    <div className='timer-text timer-success'>Link Expires in {expiredShipmentTimer}</div>
                                                }
                                                {
                                                    expiredTimeDifference >= ExpirationTimerEnum.error && expiredTimeDifference <= ExpirationTimerEnum.warning &&
                                                    <div className='timer-text timer-warning'>Link Expires in {expiredShipmentTimer}</div>
                                                }
                                                {
                                                    expiredTimeDifference < ExpirationTimerEnum.error &&
                                                    <div className='timer-text timer-error'>Link Expires in {expiredShipmentTimer}</div>
                                                }
                                            </span>
                                        }
                                        {
                                            !expiredTimeDifference && isQuoteTimerExpired &&
                                            <span className="timer-section">
                                                <div className='timer-text timer-error'>Quote has expired</div>
                                            </span>
                                        }
                                        <span className="button-section">
                                            <span>
                                                <Button type="button" variant="contained" color="success" onClick={onAcceptShipment}
                                                    disabled={isActionBtnDisabled}>
                                                    Accept
                                                </Button>
                                            </span>
                                            <span>
                                                <Button type="button" variant="contained" color="secondary" onClick={onRejectShipment}
                                                    disabled={isActionBtnDisabled}>
                                                    Reject
                                                </Button>
                                            </span>
                                            {
                                                shipmentDetailsModel.allowCounterOffer &&
                                                <span className="pl-4">
                                                    <Button type="button" variant="contained" color="primary" onClick={onCounterOfferShipment}
                                                        disabled={isActionBtnDisabled}>
                                                        Counter Offer
                                                    </Button>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {
                                shipmentDetailsModel?.isClicked && shipmentDetailsModel?.status === GridStatusEnum.awarded &&
                                <div className="email-label-wrapper accepted-text">
                                    You have Accepted the Quote
                                </div>
                            }
                            {
                                shipmentDetailsModel?.isClicked && shipmentDetailsModel?.status === GridStatusEnum.rejected &&
                                <div className="email-label-wrapper rejected-text">
                                    You have Rejected the Quote
                                </div>
                            }
                            {
                                shipmentDetailsModel?.isClicked && shipmentDetailsModel?.status === GridStatusEnum.counterOffer &&
                                <div className="email-label-wrapper accepted-text">
                                    You have Submitted a Counter offer on the Quote
                                </div>
                            }
                            <div className={shipmentDetailsModel?.isClicked ? 'email-section-wrapper email-label' : 'email-section-wrapper'}>
                                <div className="title-text">Shipment Information</div>
                                <div className="box-content">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-3 ps-0 mobile-view-pr mobile-view-mb">
                                            <div className="heading-text">Customer Information</div>
                                            <ul className="list-content">
                                                <li>
                                                    <label>Customer</label>
                                                    <div className="text">
                                                        {setCustomerName(shipmentDetailsModel)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>Reference Number</label>
                                                    <div className="text">
                                                        {checkDisplayValue(shipmentDetailsModel.shipmentNumber)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>Bolt Customer ID</label>
                                                    <div className="text">
                                                        {getTMSIdForCustomer(shipmentDetailsModel.customerName)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>Customer Account</label>
                                                    <div className="text">
                                                        {checkDisplayValue(shipmentDetailsModel.customerAccount)}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-3 ps-0 mobile-view-pr mobile-view-mb">
                                            <div className="row mb-4">
                                                <div className="heading-text">
                                                    Origin
                                                    <span className={shipmentDetailsModel.isOriginAddrCore ? 'core-text-sm' : 'noncore-text-sm'}>
                                                        {shipmentDetailsModel.isOriginAddrCore ? 'Core' : 'Non-Core'}
                                                    </span>
                                                </div>
                                                <div className="content-text-icon">
                                                    <span className="material-icons">location_on</span>
                                                    {checkDisplayValue(shipmentDetailsModel.origin)}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="heading-text">
                                                    Destination
                                                    <span className={shipmentDetailsModel.isDestAddrCore ? 'core-text-sm' : 'noncore-text-sm'}>
                                                        {shipmentDetailsModel.isDestAddrCore ? 'Core' : 'Non-Core'}
                                                    </span>
                                                </div>
                                                <div className="content-text-icon">
                                                    <span className="material-icons">location_on</span>
                                                    {checkDisplayValue(shipmentDetailsModel.destination)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-3 ps-0 mobile-view-pr mobile-view-mb">
                                            <div className="row mb-4">
                                                <div className="heading-text">Pickup Date & Time</div>
                                                <div className="content-text">
                                                    {getUsCanadaEasternDateTime(shipmentDetailsModel.pickupDateTime)}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="heading-text">Delivery Date & Time</div>
                                                <div className="content-text">
                                                    {getUsCanadaEasternDateTime(shipmentDetailsModel.deliveryDateTime)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-3 ps-0 mobile-view-pr">
                                            <div className="row mb-4">
                                                <div className="heading-text">ETA Pickup Date & Time</div>
                                                <div className="content-text">
                                                    {getUsCanadaEasternDateTime(shipmentDetailsModel.etaPickupDateTime)}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="heading-text">ETA Delivery Date & Time</div>
                                                <div className="content-text">
                                                    {getUsCanadaEasternDateTime(shipmentDetailsModel.etaDeliveryDateTime)}
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="heading-text">Link Expiration Time</div>
                                                <div className="content-text">
                                                    {getUsCanadaEasternDateTime(shipmentDetailsModel.linkExpiryDateTime)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-content">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-3 ps-0 mobile-view-pr mobile-view-mb">
                                            <div className="heading-text">Route Details</div>
                                            <ul className="list-content">
                                                <li>
                                                    <label>Calculated Transit Time</label>
                                                    <div className="text">
                                                        {checkDisplayValue(shipmentDetailsModel.calculatedTransitTime)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>Miles Per Hour</label>
                                                    <div className="text">
                                                        {shipmentDetailsModel.milesPerHour + ' mph'}
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>Customer Miles</label>
                                                    <div className="text">
                                                        {checkDisplayValue(shipmentDetailsModel.totalMiles)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>Calculated Miles</label>
                                                    <div className="text">
                                                        {checkDisplayValue(shipmentDetailsModel.calculatedMiles)}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-3 ps-0 mobile-view-pr mobile-view-mb">
                                            <div className="heading-text">Rate</div>
                                            <ul className="list-content">
                                                <li>
                                                    <label>Tariff</label>
                                                    <div className="text">
                                                        {checkNumericValue(convertToTwoDecimal(shipmentDetailsModel?.tarrif))}
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>Fuel Surcharge</label>
                                                    <div className="text">
                                                        {checkBooleanValues(shipmentDetailsModel.fuelSurcharge)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>Fuel Surcharge Amount</label>
                                                    <div className="text">
                                                        {checkNumericValue(convertToTwoDecimal(shipmentDetailsModel.fuelSurchargeAmount))}
                                                    </div>
                                                </li>
                                                {
                                                    !checkCustomer(shipmentDetailsModel.customerName) &&
                                                    <li>
                                                        <label>Include Fuel Cost In Quotes</label>
                                                        <div className="text">
                                                            {checkBooleanValues(shipmentDetailsModel.isIncludeFuelCostInQuote)}
                                                        </div>
                                                    </li>
                                                }
                                                {
                                                    showFastShipment(shipmentDetailsModel) &&
                                                    <li>
                                                        <label>Fast Shipment</label>
                                                        <div className="text">
                                                            {checkBooleanValues(shipmentDetailsModel.isFast)}
                                                        </div>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-3 ps-0 mobile-view-pr">
                                            <div className="row mb-4">
                                                <div className="heading-text">Requested Vehicle Size</div>
                                                <ul className="list-content">
                                                    <li>
                                                        <label>Vehicle Size</label>
                                                        <div className="text">
                                                            {checkDisplayValue(shipmentDetailsModel.vehicleTypeRequested)}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-3 ps-0 mobile-view-pr">
                                            <div className="row mb-4">
                                                <div className="heading-text">Base Rate</div>
                                                <ul className="list-content">
                                                    <li>
                                                        <label>Rate</label>
                                                        <div className="text">
                                                            {checkNumericValue(convertToTwoDecimal(shipmentDetailsModel.rate))}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-content">
                                    <div className="heading-text">
                                        <span>BID Notes</span>
                                    </div>
                                    <div className="notes-text">
                                        {checkDisplayValue(shipmentDetailsModel.bidNotes)}
                                    </div>
                                </div>
                                <div className="box-content">
                                    <div className="heading-text">
                                        <span>RFQ Notes</span>
                                    </div>
                                    <div className="notes-text">
                                        {checkDisplayValue(shipmentDetailsModel.rfqNote)}
                                    </div>
                                </div>
                                <div className="box-content mb-0 grid-view">
                                    <div className="row mb-3">
                                        <FreightDetails
                                            freightView='gridView'
                                            shipmentDetailsModel={shipmentDetailsModel}
                                            freightDetailStatus={CommonConstants.NoRecordsAvailable}>
                                        </FreightDetails>
                                    </div>
                                </div>
                                <div className="box-content mb-0 mobile-view">
                                    <div className="row mb-3">
                                        <FreightDetails
                                            freightView='listView'
                                            shipmentDetailsModel={shipmentDetailsModel}
                                            freightDetailStatus={CommonConstants.NoRecordsAvailable}>
                                        </FreightDetails>
                                    </div>
                                </div>
                                <div>
                                    <AcceptForm
                                        acceptFormProps={acceptFormProps}
                                        parentCallback={onAcceptFormCallback}>
                                    </AcceptForm>
                                </div>
                                <div>
                                    <RejectForm
                                        rejectFormProps={rejectFormProps}
                                        parentCallback={onRejectFormCallback}>
                                    </RejectForm>
                                </div>
                                <div>
                                    <CounterOfferForm
                                        counterFormProps={counterFormProps}
                                        parentCallback={onCounterOfferFormCallback}>
                                    </CounterOfferForm>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
}

export default EmailShipmentDetails;
