/* eslint-disable array-callback-return */
import * as _ from 'lodash';
import { GridConstants, TMSIdConstants } from '../constants/common-constants';
import { ShipmentDetailsModel } from '../data-model/common-data-model';
import { convert12HrTimeFormat, getDayFromDate } from '.';
let tokenValue = null;

export const setToken = (data) => {
    tokenValue = data;
}

export const getToken = () => {
    return tokenValue;
}

// Object Mapping
export const objectMapping = (sourceObject, mappingObject) => {
    Object.keys(sourceObject).map((key, index) => {
        if (mappingObject.hasOwnProperty(key) && sourceObject.hasOwnProperty(key)) {
            return mappingObject[key] = sourceObject[key];
        }
    });
}

// Grid Object Mapping
export const gridObjectMapping = (sourceObject, mappingObject, data) => {
    sourceObject[mappingObject].skipCount = sourceObject.skip;
    sourceObject[mappingObject].resultsPerPage = sourceObject.take;
    sourceObject[mappingObject].searchText = sourceObject.searchText;
    if (data) {
        objectMapping(data, sourceObject[mappingObject]);
    }
}

// extend Grid object
export const extendGridObject = (obj, newAttr) => { return { ...obj, ...newAttr } }

// common column sort
export const onGridColumnSort = (e, fetchCallback, remoteObj, model) => {
    if (e?.sort[0]?.field) {
        remoteObj[model] = extendGridObject(remoteObj[model],
            {
                "sortingColumns": e.sort[0].field,
                "sortingDirection": e.sort[0].dir
            })
    } else {
        remoteObj[model]["sortingColumns"] = "";
        remoteObj[model]["sortingDirection"] = "";
    }
    remoteObj["sort"] = e.sort
    fetchCallback()
}

export const checkBooleanValues = (props, boldText = true) => {
    if (props) {
        return <div className={boldText ? 'success-text text-bold' : 'success-text'}>Yes</div>;
    } else {
        return <div className={boldText ? 'error-text text-bold' : 'error-text'}>No</div>;
    }
}

export const checkDisplayValue = (props) => {
    if (!props) {
        return 'N/A'
    }
    return props;
}

export const checkNumericValue = (props) => {
    if (!props || (props && Number(props) === 0)) {
        return 'N/A'
    }
    return '$' + props;
}

export const checkMilesValue = (props) => {
    if (props) {
        return props + ' Miles';
    }
    return 'N/A';
}

export const getTMSIdForCustomer = (data) => {
    if (data) {
        const value = data.toLowerCase();
        switch (value) {
            case GridConstants.XPOCustomer:
                return TMSIdConstants.xpo;
            case GridConstants.activeAero:
                return TMSIdConstants.activeAero;
            default:
                return 'N/A'
        }
    }
    return 'N/A'
}

export const convertToTwoDecimal = (str) => {
    if (isNaN(str) || str === '0' || str === '') return str
    return Number(str).toFixed(2).toString()
}

export const mappingEmailShipmentData = (data) => {
    let shipmentData = _.assign({}, ShipmentDetailsModel);
    _.assign(shipmentData, data);
    shipmentData.id = data.shipment_Id;
    return shipmentData;
}

export const checkCustomer = (customerName) => {
    if (customerName && (customerName.toLowerCase() === GridConstants.XPOCustomer)) {
        return true;
    }
    return false;
}

export const showFastShipment = (data) => {
    if (data) {
        if (checkCustomer(data.customerName)) {
            return true;
        } else if (!checkCustomer(data.customerName) && data.isShipmentUSToCanada) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export const setCustomerName = (data) => {
    if (data?.customerName && (data?.customerName.toLowerCase() === GridConstants.XPOCustomer)) {
        let customerName;
        if (data?.shipmentTypeName && data?.shipmentCarrierId) {
            customerName = `${data.customerName}-${data.shipmentTypeName}(${data.shipmentCarrierId})`;
        } else if (data.shipmentCarrierId) {
            customerName = `${data.customerName}-${data.shipmentTypeName}`;
        } else {
            customerName = data.customerName;
        }
        return customerName;
    }
    return data.customerName;
}

export const getExpiredCountDownTimer = (days, hours, minutes, seconds, expireDateObject) => {
    return `${days > 0 ? days + 'd' : ''} ${hours > 0 ? hours + 'h' : ''} ${minutes > 0 ? minutes + 'm' : ''} ${seconds > 0 ? seconds + 's left' : ''} ${seconds === 0 ? '0s left' : ''} ${expireDateObject ? '(' + getDayFromDate(expireDateObject) + ' at ' + convert12HrTimeFormat(expireDateObject) + ')' : ''}`;
}
