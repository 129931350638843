import React from 'react';
import './page-not-found.scss';

const PageNotFound = () => (
    <div className="page-not-found-wrapper">
        <h1>404</h1>
        <h4>Oops! Page not found.</h4>
    </div>
);

export default PageNotFound;
